import { toast } from 'react-toastify';

const toastConfig = {
  position: "top-right",
  autoClose: 3500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
  style: {
    borderRadius: '12px',
    padding: '16px 24px',
    fontSize: '14px',
    lineHeight: '1.4',
    letterSpacing: '-0.01em',
    fontWeight: '500',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
    border: '0.5px solid rgba(0, 0, 0, 0.04)',
    backdropFilter: 'blur(16px)',
    background: 'rgba(250, 250, 252, 0.98)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Display", sans-serif',
    maxWidth: '380px',
    margin: '8px',
  },
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    ...toastConfig,
    icon: '􀁢',  // SF Symbol checkmark
    style: {
      ...toastConfig.style,
      color: '#17B26A',
      background: 'rgba(236, 253, 243, 0.98)',
    },
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    ...toastConfig,
    icon: '􀁡',  // SF Symbol exclamation
    style: {
      ...toastConfig.style,
      color: '#F02849',
      background: 'rgba(255, 242, 242, 0.98)',
    },
  });
};

export const showInfoToast = (message) => {
  toast.info(message, {
    ...toastConfig,
    icon: '􀅴',  // SF Symbol info
    style: {
      ...toastConfig.style,
      color: '#0066FF',
      background: 'rgba(242, 247, 255, 0.98)',
    },
  });
};
