import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { showSuccessToast, showErrorToast } from '../utils/toast';

function LoginForm({ handleLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (recaptchaToken && !isLoggingIn) {
      setIsLoggingIn(true);
      
      try {
        await handleLogin(email, password);
        showSuccessToast("Successfully logged in! Welcome back!");
      } catch (error) {
        showErrorToast(error.message || "Login failed. Please try again.");
      } finally {
        setIsLoggingIn(false);
      }
    } else if (!recaptchaToken) {
      showErrorToast("Please verify that you are not a robot");
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <section className="bg-gray-50 rounded-xl p-4 shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Login</h2>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            required
          />
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            required
          />
        </div>
        <ReCAPTCHA
          sitekey="6LdgbTAqAAAAANrHylxssZEKe3cUoZMwOlYQBc_M"
          onChange={onRecaptchaChange}
        />
        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white p-3 rounded-lg hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out text-base font-semibold flex items-center justify-center"
          disabled={!recaptchaToken || isLoggingIn}
        >
          {isLoggingIn ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Logging in...
            </>
          ) : (
            'Login'
          )}
        </button>
      </form>
    </section>
  );
}

export default LoginForm;
