import { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';

const useAttendance = () => {
  const [attendance, setAttendance] = useState([]);

  const handleAddAttendance = async ({ rollNumber, date, semester, professor, academicYear }) => {
    try {
      const attendanceQuery = query(
        collection(db, academicYear, professor, `Semester ${semester}`),
        where("rollNumber", "==", rollNumber),
        where("date", "==", date)
      );
      const querySnapshot = await getDocs(attendanceQuery);

      if (querySnapshot.empty) {
        await addDoc(
          collection(db, academicYear, professor, `Semester ${semester}`),
          { rollNumber, date }
        );
        await fetchAttendance({ semester, professor, academicYear });
        return { success: true };
      } else {
        return { 
          success: false, 
          error: "Attendance already marked for this roll number today"
        };
      }
    } catch (error) {
      console.error("Error adding attendance data: ", error);
      throw error;
    }
  };

  const fetchAttendance = async ({ semester, professor, academicYear }) => {
    try {
      const attendanceQuery = query(
        collection(db, academicYear, professor, `Semester ${semester}`)
      );
      const querySnapshot = await getDocs(attendanceQuery);
      const attendanceArray = querySnapshot.docs.map((doc) => doc.data());
      setAttendance(attendanceArray);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
      throw error;
    }
  };

  return { attendance, handleAddAttendance };
};

export default useAttendance;