import React, { useState } from 'react';
import { SEMESTERS, PROFESSORS, ACADEMIC_YEARS } from '../utils/constants';
import { showErrorToast } from '../utils/toast';

function StatisticsForm({ handleFetchStats, filterProfessor, setFilterProfessor, filterSemester, setFilterSemester, filterAcademicYear, setFilterAcademicYear }) {
  const [isFetching, setIsFetching] = useState(false);
  const isFilterValid = () => filterSemester && filterProfessor && filterAcademicYear;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFilterValid() && !isFetching) {
      setIsFetching(true);
      
      try {
        await handleFetchStats({ filterSemester, filterProfessor, filterAcademicYear });
      } catch (error) {
        showErrorToast(error.message || "Failed to fetch statistics");
      } finally {
        setIsFetching(false);
      }
    }
  };

  return (
    <section className="bg-gray-50 rounded-xl p-4 shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Fetch Statistics</h2>
      <form className="space-y-4" onSubmit={onSubmit}>
        <select
          value={filterSemester}
          onChange={(e) => setFilterSemester(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Semester</option>
          {SEMESTERS.map((sem) => (
            <option key={sem} value={sem}>Semester {sem}</option>
          ))}
        </select>
        <select
          value={filterProfessor}
          onChange={(e) => setFilterProfessor(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Professor</option>
          {PROFESSORS.map((prof) => (
            <option key={prof} value={prof}>{prof}</option>
          ))}
        </select>
        <select
          value={filterAcademicYear}
          onChange={(e) => setFilterAcademicYear(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          {ACADEMIC_YEARS.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <button
          type="submit"
          disabled={!isFilterValid() || isFetching}
          className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 text-white p-3 rounded-lg hover:from-green-600 hover:to-blue-600 transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed text-base font-semibold flex items-center justify-center"
        >
          {isFetching ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Fetching...
            </>
          ) : (
            'Fetch Statistics'
          )}
        </button>
      </form>
    </section>
  );
}

export default StatisticsForm;