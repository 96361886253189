import React, { useState } from 'react';
import { SEMESTERS, PROFESSORS, ACADEMIC_YEARS } from '../utils/constants';
import { showSuccessToast, showErrorToast } from '../utils/toast';

function AttendanceForm({ handleAddAttendance, professor, setProfessor, semester, setSemester, academicYear, setAcademicYear }) {
  const [rollNumber, setRollNumber] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormValid = () => rollNumber && date && semester && professor && academicYear;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid() && !isSubmitting) {
      setIsSubmitting(true);
      
      try {
        const result = await handleAddAttendance({ 
          rollNumber, 
          date, 
          semester, 
          professor, 
          academicYear 
        });
        
        if (result.success) {
          showSuccessToast(`Attendance marked for Roll No: ${rollNumber}`);
          setRollNumber("");
        } else {
          showErrorToast(result.error);
        }
      } catch (error) {
        showErrorToast("Failed to mark attendance");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <section className="bg-gray-50 rounded-xl p-4 shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Add Attendance</h2>
      <form className="space-y-4" onSubmit={onSubmit}>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        />
        <select
          value={semester}
          onChange={(e) => setSemester(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Semester</option>
          {SEMESTERS.map((sem) => (
            <option key={sem} value={sem}>Semester {sem}</option>
          ))}
        </select>
        <select
          value={professor}
          onChange={(e) => setProfessor(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Professor</option>
          {PROFESSORS.map((prof) => (
            <option key={prof} value={prof}>{prof}</option>
          ))}
        </select>
        <select
          value={academicYear}
          onChange={(e) => setAcademicYear(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          {ACADEMIC_YEARS.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
   <input
  type="number"
  value={rollNumber}
  onChange={(e) => setRollNumber(e.target.value)}
  placeholder="Enter roll number"
  className="w-full p-4 border border-gray-300 rounded-lg"
/>
        <button
          type="submit"
          disabled={!isFormValid() || isSubmitting}
          className="w-full mt-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white p-3 rounded-lg hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed text-base font-semibold flex items-center justify-center"
        >
          {isSubmitting ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Adding...
            </>
          ) : (
            'Submit'
          )}
        </button>
      </form>
    </section>
  );
}

export default AttendanceForm;