import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { notifySuccess, notifyError } from '../utils/notifications';

function useAuth() {
  const [user, setUser] = useState(null);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Check if user is already logged in (e.g., check localStorage or session)
    const checkAuth = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      } finally {
        setIsCheckingAuth(false);
      }
    };

    checkAuth();

    return () => unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      notifySuccess("Logged in successfully");
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      notifyError("Login failed: " + error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      notifySuccess("Logged out successfully");
      setUser(null);
      localStorage.removeItem('user');
    } catch (error) {
      notifyError("Logout failed: " + error.message);
    }
  };

  return { user, handleLogin, handleLogout, isCheckingAuth };
}

export default useAuth;